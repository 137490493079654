import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function AdminNavbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
const [planNumber, setPlanNumber] = useState("");
  const [planCost, setPlanCost] = useState("");
  const [planlimit, setPlanLimit] = useState("");
  const [orderlimit, setOrderLimit] = useState("");
  const [planstatus, setPlanStatus] = useState("");


  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };


  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear();

    // Redirect to login page
    window.location.href = "/adminlogin";
    localStorage.removeItem("un");
    localStorage.clear();
  };

const addPlan = async (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append('plannumber', planNumber);
    formdata.append('plancost', planCost);
    formdata.append('planlimit', planlimit);
    formdata.append('orderlimit', orderlimit);
    formdata.append('planstatus', planstatus);

    const url = `${process.env.REACT_APP_API_URL}addplan`;
    try {
      const response = await fetch(url, { method: "POST", body: formdata });
      const ans = await response.text();
      if (ans === "success") {
        setPlanNumber('');
        setPlanCost('');
        setPlanLimit('');
        setOrderLimit('');
        setPlanStatus('');
        Swal.fire({
          icon: 'success',
          title: 'Plan Added Successful',
          text: 'You have successfully Added Plan!',
          showConfirmButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/adminshowplan";
            }
          });

        // Refresh menu items

      } else if (ans === "fail") {
        Swal.fire({
          icon: 'error',
          title: 'Plan already exist',
          text: 'Add New Plan!',
          showConfirmButton: true,
        })
      } else if (ans === "exception") {
        alert(ans);
      }
    } catch (error) {
      console.error('Error adding item:', error);
    }

  }


  return (
    <>

 <div className="modal fade" id="myModalPlan">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title">Add Plans</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            {/* Modal body */}
            <div className="modal-body">




              <form
                className="custom-form"
                action="https://restabook.kwst.net/light/php/reservation.php"
                name="reservationform"
                id="reservationform"
              >
                <fieldset>
                  <div className="row">

                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Plan Number *"
                        defaultValue=""
                        value={planNumber}
                        onChange={(e) => setPlanNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Plan Cost *"
                        defaultValue=""
                        value={planCost}
                        onChange={(e) => setPlanCost(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Menu Limit *"
                        defaultValue=""
                        value={planlimit}
                        onChange={(e) => setPlanLimit(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Order Limit *"
                        defaultValue=""
                        value={orderlimit}
                        onChange={(e) => setOrderLimit(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Plan Active/InActive *"
                        defaultValue=""
                        value={planstatus}
                        onChange={(e) => setPlanStatus(e.target.value)}
                      />
                    </div>



                  </div>


                  <button className="btn color-bg" id="reservation-submit" style={{ backgroundColor: "#C19D60" }} onClick={addPlan}>
                    Add Now <i className="fal fa-long-arrow-right" />
                  </button>
                </fieldset>
              </form>

            </div>

            {/* Modal footer */}

          </div>
        </div>
      </div>




      <div className="header-inner  fl-wrap">
        <div className="container">
          <div className="header-container fl-wrap">
            <a href="admindashboard" className="logo-holder">
            <img src="images/Food.jpg" alt="" style={{height:"40px" , width:"240px"}}/>
            </a>
            <div className="show-reserv_button show-rb" onClick={handleLogout}>
              <span>LogOut</span> <i className="fal fa-bookmark" />
            </div>

            {/* nav-button-wrap*/}
            <div className="nav-button-wrap" onClick={handleNavToggle}>
              <div className="nav-button">
                <span />
                <span />
                <span />
              </div>
            </div>
            {/* nav-button-wrap end*/}
            {/*  navigation */}
            <div className={`nav-holder main-menu ${isNavOpen ? 'open' : ''}`}>
              <nav>
                <ul>
                  <li>
                    <a href="/admindashboard" className="act-link">
                      Dashboard <i className="fas fa-caret-down" />
                    </a>
                    {/*second level end*/}
                  </li>
                  <li>
                    <Link to="/managemenu">
                      Menu
                      <i className="fas fa-caret-down" />
                    </Link>
                    {/*second level */}

                    {/*second level end*/}
                  </li>
                  <li>
                    <Link to="/userManageOrders">Orders</Link>
                  </li>
                  {/* <li>
                    <a href="#">Reservations</a>
                  </li> */}
                  {/* <li>
                    <a href="#">FeedBacks</a>
                  </li> */}

 <li>
                    <a href="#">
                      Plans
                      <i className="fas fa-caret-down" />
                    </a>
                 
                    <ul>
                    <li>
                    <Link to="" data-bs-toggle="modal"
                      data-bs-target="#myModalPlan"
                    >Add Plans</Link>

                  </li>
                  <li>
                    <Link to="/admineditplan"> Edit Plans</Link>

                  </li>
                
                   
                    </ul>
                  
                  </li>



                  <li>
                    <a href="#">
                      Users
                      <i className="fas fa-caret-down" />
                    </a>
                    {/*second level */}
                    <ul>
                      <li>
                        <a href="/usersviewdetail">View Users</a>
                      </li>
                      {/* <li>
                        <a href="product-single.html">Product Single</a>
                      </li>
                      <li>
                        <a href="cart.html">Cart</a>
                      </li> */}
                      {/* <li>
                        <a href="gallery.html">Gallery</a>
                      </li>
                      <li>
                        <a href="blog-single.html">Blog single</a>
                      </li>
                      <li>
                        <a href="404.html">404</a>
                      </li>
                      <li>
                        <a href="coming-soon.html">Coming Soon</a>
                      </li> */}
                    </ul>
                    {/*second level end*/}
                  </li>
                </ul>
              </nav>
            </div>
            {/* navigation  end */}
            {/* header-cart_wrap  */}
            <div className="header-cart_wrap novis_cart">

              <div className="header-cart_wrap_container fl-wrap">
                <div className="box-widget-content">
                  <div className="widget-posts fl-wrap">

                  </div>
                </div>
              </div>
              <div className="header-cart_wrap_total fl-wrap">
                <div className="header-cart_wrap_total_item">
                  Subtotal : <span>$147</span>
                </div>
              </div>

            </div>
            {/* header-cart_wrap end  */}
            {/* share-wrapper */}
            <div className="share-wrapper isShare">
              <div className="share-container fl-wrap" />
            </div>
            {/* share-wrapper-end */}
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminNavbar;
