import { useEffect, useRef, useState } from "react";
import './'
import AdminNavbar from "./AdminNavbar";
import UserNavbar from "./UserNavbar";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const AdminShowPlan = ({ plan }) => {

  const [adminPlan, setAdminPlan] = useState([]);
  const [isPlan, setIsPlan] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [activePlanId, setActivePlanId] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [subAdminPlanData, setSubAdminPlanData] = useState(null);
  const [currentUser, setcurrentUser] = useState(null);
   const [customPlanData, setCustomPlanData] = useState([]);

  const uEmail = localStorage.getItem("useremail");
  const navigate = useNavigate();

  useEffect(() => {
    if (!uEmail) {
      navigate("/userlogin")
    }
  }, [uEmail, navigate])

  const showPlan = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}showplans`, { method: "POST" });
      const text = await response.text();
      const data = JSON.parse(text.trim());
      setAdminPlan(data.ans);
      console.log(adminPlan)

    } catch (error) {
      console.error('Error fetching menu items:', error);
    }

  }

  useEffect(() => {
    showPlan();

  }, [])

  const handleSubscription = async (plan) => {

    const planid = plan.id;

    try {
      // Create Razorpay order (call backend)
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/payment/plan/create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: plan.plan_cost * 100, // Amount in paise (INR)
          userId: uEmail, // Pass user email or ID
          planId: plan.id
        })
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Backend error:', errorMessage);
        alert('Failed to create order. Please try again.');
        return;
      }

      const orderData = await response.json();
      console.log('Order data:', orderData);

      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Use your Razorpay Key from env variables
        amount: orderData.amount,
        currency: orderData.currency,
        name: "FoodFusion",
        description: `Subscription to Plan ${plan.plan_number}`,
        order_id: orderData.id,
        handler: async function (response) {
          try {
            const verificationResponse = await fetch(`${process.env.REACT_APP_API_URL}api/payment/plan/verify`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              }),
            });

            if (!verificationResponse.ok) {
              const errorMessage = await verificationResponse.text();
              console.error('Backend verification error:', errorMessage);
              alert('Payment verification failed. Please try again.');
              return;
            }

            // Try to parse the response as JSON
            const verificationData = await verificationResponse.json().catch(() => {
              console.error('Invalid JSON response');
              alert('Payment verification failed. Invalid response from server.');
              return null;
            });

            if (verificationData && verificationData.verified) {
              const formdata = new FormData();
              formdata.append('planid', planid);
              formdata.append('useremail', uEmail);

              const url = `${process.env.REACT_APP_API_URL}api/payment/addsubscription`;
              const addSubscriptionResponse = await fetch(url, { method: "POST", body: formdata });
              const ans = await addSubscriptionResponse.text();

              if (ans === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'payment Successful',
                  text: 'You have successfully Active the plan',
                  showConfirmButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/createpanel";
                  }
                });
              } else if (ans === "fail") {
                Swal.fire({
                  icon: 'error',
                  title: 'Plan cannot be edited',
                  text: 'Edit New Plan!',
                  showConfirmButton: true,
                });
              } else if (ans === "exception") {
                alert(ans);
              }
            } else {
              alert('Payment verification failed. Please contact support.');
            }
          } catch (error) {
            console.error('Verification error:', error);
            alert('Error during payment verification. Please try again.');
          }
        }
        ,
        prefill: {
          email: uEmail,
          contact: "9999999999", // User's contact number
        },
        theme: {
          color: "#3399cc"
        }
      };

      if (window.Razorpay) {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } else {
        console.error('Razorpay SDK not loaded');
        alert('Oops! Something went wrong. Please refresh the page and try again.');
      }

    } catch (error) {
      console.error('Payment error:', error);
      alert('Error during subscription. Please try again.');
    }
  };




  const checkPlan = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/payment/checkplans`, { method: "POST" });
      const text = await response.text();
      const data = JSON.parse(text.trim());
      setIsPlan(data.ans);
       console.log(data.ans);
      const currentuser = data.ans.filter(user => user.user_email === uEmail)
      setcurrentUser(currentuser);

        setActivePlanId(data.ans.sub_admin_plan_id); // Set the active plan ID
    

    } catch (error) {
      console.error('Error fetching plan:', error);
    }

  }
  useEffect(() => {
    checkPlan();
    
  }, [])
 


  useEffect(() => {
    // Check conditions for disabling the button and setting the warning message
    if (isPlan && isPlan.plan_status) {
      if (isPlan.plan_status.trim().toLowerCase() === 'active' && activePlanId > plan.id) {
        setWarningMessage("You cannot downgrade to this plan; it is your active plan.");
        setIsButtonDisabled(true); // Disable button
      } else {
        setWarningMessage(""); // Clear message if conditions are not met
        setIsButtonDisabled(false); // Enable button
      }
    }
  }, [isPlan, activePlanId, plan]); // Remove `plan.id` and use `plan` directly



  const showCustomPlan = async () => {
    try {
      const formdata = new FormData();
      formdata.append("useremail" , uEmail);

      const response = await fetch(`${process.env.REACT_APP_API_URL}showcustomplans`, { method: "POST" , body: formdata});
      const text = await response.text();
      const data = JSON.parse(text.trim());
      setCustomPlanData(data.ans);
      

    } catch (error) {
      console.error('Error fetching Data:', error);
    }

  }

  useEffect(() => {
    showCustomPlan();

  }, [])

  const customHandleSubscription = async (customplan) => {

    const planid = customplan.id;

    try {
      console.log('Custom plan data:', customplan);
      // Create Razorpay order (call backend)
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/payment/plan/create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: customplan.custom_plan_cost * 100, // Amount in paise (INR)
          userId: uEmail, // Pass user email or ID
          planId: customplan.id
        })
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Backend error:', errorMessage);
        alert('Failed to create order. Please try again.');
        return;
      }

      const orderData = await response.json();
      console.log('Order data:', orderData);

      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Use your Razorpay Key from env variables
        amount: orderData.amount,
        currency: orderData.currency,
        name: "FoodFusion",
        description: `Subscription to Plan ${customplan.custom_plan_number}`,
        order_id: orderData.id,
        handler: async function (response) {
          try {
            const verificationResponse = await fetch(`${process.env.REACT_APP_API_URL}api/payment/plan/verify`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              }),
            });

            if (!verificationResponse.ok) {
              const errorMessage = await verificationResponse.text();
              console.error('Backend verification error:', errorMessage);
              alert('Payment verification failed. Please try again.');
              return;
            }

            // Try to parse the response as JSON
            const verificationData = await verificationResponse.json().catch(() => {
              console.error('Invalid JSON response');
              alert('Payment verification failed. Invalid response from server.');
              return null;
            });

            if (verificationData && verificationData.verified) {
              const formdata = new FormData();
              formdata.append('planid', planid);
              formdata.append('useremail', uEmail);

              const url = `${process.env.REACT_APP_API_URL}api/payment/addcustomsubscription`;
              const addSubscriptionResponse = await fetch(url, { method: "POST", body: formdata });
              const ans = await addSubscriptionResponse.text();

              if (ans === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'payment Successful',
                  text: 'You have successfully Active the plan',
                  showConfirmButton: true,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/createpanel";
                  }
                });
               
              
              } 
              
              else if (ans === "fail") {
                Swal.fire({
                  icon: 'error',
                  title: 'Plan cannot be edited',
                  text: 'Edit New Plan!',
                  showConfirmButton: true,
                });
              } else if (ans === "exception") {
                alert(ans);
              }
            } else {
              alert('Payment verification failed. Please contact support.');
            }
          } catch (error) {
            console.error('Verification error:', error);
            alert('Error during payment verification. Please try again.');
          }
        }
        ,
        prefill: {
          email: uEmail,
          contact: "9999999999", // User's contact number
        },
        theme: {
          color: "#3399cc"
        }
      };

      if (window.Razorpay) {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } else {
        console.error('Razorpay SDK not loaded');
        alert('Oops! Something went wrong. Please refresh the page and try again.');
      }

    } catch (error) {
      console.error('Payment error:', error);
      alert('Error during subscription. Please try again.');
    }
  };



  return (
    <>
      <UserNavbar />
      <section className="parallax-section hero-section hidden-section" data-scrollax-parent="true">
        <img className="bg par-elem" src="images/bg/17.jpg" alt="img" data-scrollax="properties: { translateY: '30%' }" />
        <div className="overlay" />
        <div className="container">
          <div className="section-title" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <h4>View  Plans</h4>
            <h2>join Us</h2>
            <div className="dots-separator fl-wrap">
              <span />
            </div>
          </div>
        </div>
        <div className="hero-section-scroll">
          <div className="mousey">
            <div className="scroller" />
          </div>
        </div>
        <div className="brush-dec" />
      </section>


      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />



      <div className="plancard-container">

{customPlanData.map((customplan, index) => {
          const currentPlanId = currentUser?.[0]?.sub_admin_plan_id;
        //  console.log("curent plan id",plan)
         
          // const isInactive = plan.plan_status.trim().toLowerCase() === 'inactive';
          // const isDowngrade = currentPlanId !== null && plan.id < currentPlanId;

          // if (isDowngrade) {
          //   return null;
          // }
        
          return  <div className="plancard" key={index}>
              <div className={`active-badge ${customplan.custom_plan_status.trim().toLowerCase() === 'inactive' ? 'inactive' : ''}`}>{customplan.custom_plan_status}</div>
              <h2 className="plancard-title">Custom Plan {customplan.custom_plan_number}</h2>
              <p className="planprice">
                ${customplan.custom_plan_cost}<span>/month</span>
              </p>
              <ul className="planfeatures">
                <li>manage menu,users and orders</li>
                <li>24/7 Customer Support</li>
                <li>{customplan.custom_plan_menuitems} Food Items </li>

                <li>Limited to {customplan.custom_plan_order} Orders/Day</li>

              </ul>

              <button className="plansubscribe-btn"
                //  disabled={plan.plan_status.trim().toLowerCase() === 'inactive'}
                //  disabled={isButtonDisabled}
                // disabled={isInactive || isDowngrade}
                onClick={() => customHandleSubscription(customplan)}
                > Subscribe Now
                 {/* { console.log(plan.plan_number,currentPlanId)} */}
              {/* {customplan.id == currentPlanId ? "Current Plan" : "Subscribe Now"} */}
              </button>
            
              {/* {isInactive && <p className="warning-message">This plan is inactive.</p>} */} 
              {/* {isDowngrade && <p className="warning-message">You cannot downgrade to a lower plan.</p>} */}
            
             
           

            </div>
        
        })}

        {adminPlan.map((plan, index) => {
          const currentPlanId = currentUser?.[0]?.sub_admin_plan_id;
         console.log("curent plan id",plan)
         
          const isInactive = plan.plan_status.trim().toLowerCase() === 'inactive';
          const isDowngrade = currentPlanId !== null && plan.id < currentPlanId;
 if (isDowngrade) {
            return null;
          }

          return (
            <div className="plancard" key={index}>
              <div className={`active-badge ${plan.plan_status.trim().toLowerCase() === 'inactive' ? 'inactive' : ''}`}>{plan.plan_status}</div>
              <h2 className="plancard-title">Premium Plan {plan.plan_number}</h2>
              <p className="planprice">
                ${plan.plan_cost}<span>/month</span>
              </p>
              <ul className="planfeatures">
                <li>manage menu,users and orders</li>
                <li>24/7 Customer Support</li>
                <li>{plan.menu_limit} Food Items </li>

                <li>Limited to {plan.order_limit} Orders/Day</li>

              </ul>

              <button className="plansubscribe-btn"
                //  disabled={plan.plan_status.trim().toLowerCase() === 'inactive'}
                //  disabled={isButtonDisabled}
                disabled={isInactive || isDowngrade}
                onClick={() => handleSubscription(plan)}>
                 { console.log(plan.plan_number,currentPlanId)}
              {plan.id == currentPlanId ? "Current Plan" : "Subscribe Now"}</button>
              {isInactive && <p className="warning-message">This plan is inactive.</p>}
           
             
           

            </div>
          );
        })}

      </div>



<div className="">

  <div className="tooltip-container">
    <span className="tooltip">9899990000</span>
    <span className="text">Contact Us</span>
    <svg id="Calque_1" viewBox="0 0 377 270" xmlns="http://www.w3.org/2000/svg">
      <g id="phone">
        <g id="caractere_x5F_1">
          <g>
            <path
              id="right_x5F_hair"
              className="st1"
              d="M199,87.1c-0.9-2.6-1.2-5.3-1.2-8.2c0.2,0.4,0.3,0.8,0.5,1.1c1.4,3.1,2.9,5.8,4.4,8
				c-1.2-2.8-2.6-6.9-3.2-12.1c-0.4-4.6,0-8.5,0.5-11.3c2.2-10.3,4.6-21.2-1.6-31.5c-10.9-18.3-33.6-8.4-33.6-8.4L151,110.2
				c0,0,17.8,28.2,42,18.3C217.2,118.7,204.4,102.4,199,87.1z"
            />
            <g id="neck_2_">
              <path
                id="neck_x5F_and_x5F_shoulder"
                className="st2"
                d="M175.4,146.4c-12.7,2.3-56.5-13-69.2-11c-1.1-8.3,6.2-16.7,9.8-19.2
					c10-6.9,28,4.2,27.3-17.8c-0.8-22.9-1.6-28.3-1.6-28.3l26.2,17.5c0,0-5.6,8.1,0,19.7c5.2,10.6,23-2,29.8,11
					C180.1,123.5,193.3,143.2,175.4,146.4z"
              />
              <path
                id="shadow_x5F_head"
                className="st3"
                d="M166.8,104.9c-10.9,1.5-20.9-4.5-23.9-14.5c-0.7-16.2-1.2-20.4-1.2-20.4l26.2,17.5
					C167.8,87.6,163,94.6,166.8,104.9z"
              />
            </g>
            <g id="face">
              <path
                id="face_4_"
                className="st2"
                d="M188.2,78c-0.5,4.5-2.8,7.9-4,9.8c-1.1,1.7-1,1.1-5.4,6.3c-3.8,4.5-4,5-5.2,5.5
					c-1.5,0.6-3.7,0.6-10.4-3.1c-4.9-2.7-8-4.4-11.8-8c-4.2-3.9-11.5-10.6-13.8-21.6c-0.9-4.2-3.3-16.1,23.3-31.2
					c0,0,10.4-4.1,18.5,1.2c7.5,5,8.5,14.4,8.7,16.2c0.6,6.2-1.8,8.2-1.1,15.2c0.1,0.5,0.1,1,0.2,1.6l0,0
					C187.8,73.4,188.6,74.8,188.2,78z"
              />
              <g>
                <g id="nose">
                  <path
                    className="st4"
                    d="M176.7,63.3c0.9,1.9,1.6,3.8,2.4,5.5c0.4,0.9,0.9,1.8,1.3,2.5c0.5,0.8,1.1,1.5,1.8,1.8l1.3,0.6l-1.2,0.6
							c-1.1,0.5-2.1,1-3.2,1.4c-1.1,0.4-2.2,0.8-3.4,0.9c0.5-0.2,1.1-0.5,1.6-0.8s1-0.6,1.5-0.9c1-0.6,2-1.2,2.9-1.8l0.1,1.2
							c-1.2-0.4-2-1.3-2.6-2.2c-0.6-0.9-1.1-1.9-1.4-2.9c-0.4-1-0.6-2-0.8-3C176.7,65.4,176.6,64.4,176.7,63.3z"
                  />
                </g>
                <g id="left_x5F_eyebrow">
                  <path
                    className="st5"
                    d="M153.1,55.8c0.3-0.7,0.8-1.4,1.3-2s1.1-1.1,1.8-1.6c1.3-0.9,3-1.5,4.7-1.6c1.8-0.1,3.6,0.5,4.9,1.5
							c1.4,1,2.4,2.4,2.6,4.1c-1.5-0.7-2.8-1.2-4-1.4c-1.2-0.3-2.3-0.4-3.4-0.4c-1.1,0.1-2.3,0.2-3.6,0.5c-0.6,0.1-1.3,0.3-2,0.5
							C154.6,55.5,153.9,55.7,153.1,55.8z"
                  />
                </g>
                <g id="right_x5F_eyebrow">
                  <path
                    className="st5"
                    d="M188.6,55.7c-1.2-0.8-2.2-1.3-3.1-1.5c-0.9-0.2-1.6-0.1-2.6,0h-0.3l-0.4,0.1l-0.7,0.2
							c-0.5,0.2-1,0.3-1.6,0.4c-0.6,0.2-1.1,0.3-1.8,0.5c-0.6,0.1-1.3,0.3-2,0.3c0.2-0.7,0.6-1.3,1-1.9c0.4-0.6,0.9-1,1.4-1.5
							s1.1-0.8,1.7-1.2c0.3-0.2,0.7-0.3,1-0.4l0.5-0.2l0.6-0.1c1.5-0.3,3.4,0,4.6,1.1C188.2,52.7,188.8,54.3,188.6,55.7z"
                  />
                </g>
              </g>
              <path
                id="mouth"
                className="st5"
                d="M159.1,79.6c0,0,0.3,3.2,2.5,5.8c0.3,0.4,0.7,0.8,1.1,1.1c1.9,1.6,4.7,2.7,9.1,2.1
					c0.5-0.1,0.9-0.2,1.3-0.4c0.4-0.2,0.9-0.4,1.2-0.6c3.4-2.1,3.9-6.4,3.9-6.4s-0.7,0.2-1.8,0.4c-2.8,0.6-8.5,1.1-14.8-1.1
					C160.7,80.3,159.9,80,159.1,79.6z"
              />
              <path
                id="tongue"
                className="st6"
                d="M162.7,86.5c1.9,1.6,4.7,2.7,9.1,2.1c0.5-0.1,0.9-0.2,1.3-0.4c-0.3-1.5-2.9-2.7-6-2.7
					C165.3,85.5,163.8,85.9,162.7,86.5z"
              />
              <path
                id="thoot"
                className="st7"
                d="M161.6,80.6c0,0.7,0.4,1.6,1.7,1.9c2.5,0.5,8.6,1.4,12.4,0.4c0,0,0.5-0.4,0.7-1.2
					C173.6,82.3,167.8,82.9,161.6,80.6z"
              />
              <path
                id="eye_x5F_right"
                className="st5"
                d="M186,63.8c-1.2-0.5-2.1-0.9-3.2-0.9c-1-0.1-2.1,0.1-3.3,0.4c0.3-0.6,0.9-1,1.4-1.3
					c0.6-0.3,1.3-0.4,2-0.3C184.2,61.7,185.5,62.5,186,63.8z"
              />
              <path
                id="eye_x5F_left"
                className="st5"
                d="M158.4,62.6c1-1.4,2.9-2.3,4.7-2.1c0.9,0.1,1.8,0.3,2.6,0.8s1.4,1.1,1.9,1.9
					c-0.8-0.4-1.5-0.8-2.3-1c-0.8-0.3-1.5-0.4-2.3-0.5s-1.5,0-2.3,0.2C160,62,159.2,62.3,158.4,62.6z"
              />
            </g>
            <path
              id="hair_x5F_left"
              className="st5"
              d="M171.3,32.2c0.1-1.7-0.1-3.7-1.1-5.7c-4.5-8.2-21.3-6.8-29.6-5.2
				C107,27.8,120.4,92,120.4,92c-21.2,9.9-11.9,28.2-11.9,28.2c1.5-1.5,4-3.4,7.4-3.9c4.6-0.7,7.1,1.7,12.1,1.9
				c3.9,0.2,8.2-1.4,11.1-2.9c14.7-7.8-1-32.5,5.5-46.7c-0.1,1.2-0.1,2.4-0.2,3.7c-0.1,3.5,0.6,6.4,1.1,8.3
				c-0.2-2.1-0.6-9.3,4.2-15.8c1.9-2.5,3.9-4.1,5.7-5.2c0.3-0.2,0.6-0.3,0.9-0.5c0.1-0.1,0.2-0.1,0.3-0.2l0,0
				C173.4,48.2,171.3,32.2,171.3,32.2z"
            />
            <ellipse
              id="left_x5F_cheek_2_"
              transform="matrix(8.249854e-02 -0.9966 0.9966 8.249854e-02 79.3047 221.9763)"
              className="st8"
              cx="160.2"
              cy="67.9"
              rx="1.3"
              ry="3.7"
            />
            <path
              id="right_x5F_cheeck"
              className="st8"
              d="M187.3,69.9L187.3,69.9c-0.6,0.1-1.3,0.2-2.1,0.1c-1.6-0.1-2.9-0.7-2.9-1.3
				c0.1-0.6,1.4-0.9,3.1-0.8c0.6,0.1,1.2,0.2,1.7,0.3C187.1,68.8,187.2,69.3,187.3,69.9z"
            />
          </g>
          <g id="left_x5F_arm_2_">
            <path
              className="st3"
              d="M253.8,129.2c1.8-0.9,3.4-0.4,3.9,0.4c0.4,0.6-0.8,0.8-2.2,2.4c-1.4,1.7-3.4,5.2-3.4,5.2l-2.1-3.8
				C249.9,133.4,251.6,130.3,253.8,129.2z"
            />
            <path
              className="st2"
              d="M252.4,140.8c-0.4,5.4-0.7,9.3-1.9,13.4c-0.2,0.7-0.8,2.7-1.6,5.1c-0.1,0.3-0.2,0.7-0.3,1
				c-0.4,1.1-0.7,2.1-0.9,2.7c0,0.1-0.1,0.2-0.1,0.3c-3.6-2.6-6.1-3-7.7-2.7c-0.4,0.1-1.6,0.3-2.8-0.2c-1-0.4-1.6-1.2-2-1.8
				c1.2-1.4,2.1-2.7,2.6-3.6c0.5-1,1.1-2.1,1.3-2.6c0.3-0.7,0.6-1.3,0.8-1.7c-0.1-1-0.3-2-0.4-2.9c-0.4-2.8-0.6-5.5-0.9-8.2
				c0,0-1.6-2.2-1.8-2.5c-0.2-0.2-0.7-0.4-0.9-0.9c-0.4-1.8,0.7-3.2,0.7-3.2s-0.4-0.9,0-2.1s1.3-2.2,1.3-2.2s-0.5-2.7,0-3.3
				c0.5-0.6,5.4-3.1,6.4-2.8c1,0.3,4.1,5.2,4.1,5.2s-0.3-2.2-0.8-3.4c-0.5-1.2-0.5-2.7-3.8-4.5c-3.8-2.1-3.8-3.7-3.3-4.3
				c0.5-0.5,2.9,0.8,4.5,1.6c1.6,0.8,3.1,2.3,5.1,4c2,1.6,1.5,3.1,1.9,4.9c0.5,1.8,0.2,3.6,0.6,4.7
				C252.8,131.3,252.9,134.4,252.4,140.8z"
            />
            <path
              className="st3"
              d="M237.8,136c0.5,0.1,1.3,2.5,2.2,2.3c0.2-0.1,1.3-0.5,1.3-0.8c0,0-0.9-1.2-1.6-1.7c-0.7-0.5-1.4-1.2-2.1-1.4
				c-0.9-0.1-2,0.7-1.7,1.6c0.1,0.4,0,0.7,0.6,1.8c0.4,0.8,1.4,2.2,1.9,2.6c0.4,0.3,0.6-0.7,0.7-1.5
				C239.1,138.6,238.1,136.5,237.8,136z"
            />
            <path
              className="st3"
              d="M243.3,135c0.4,0-1.6-2.7-1.9-3c-0.6-0.7-1.3-1.7-2.1-2.2c-0.9-0.5-2-0.2-2.5,0.7c-0.3,0.7-0.5,1.6-0.2,2.4
				c0.6-1.1,1.2-1.6,2.4-1C240.4,132.5,243.3,135,243.3,135z"
            />
            <path
              className="st3"
              d="M244.9,127.7c-0.4-1.1-0.8-1.4-1.3-2.4c-0.2-0.4-0.4-0.9-0.9-0.9c-0.4,0-1.3,0.4-1.7,0.6
				c-1.3,0.6-2.9,0.9-3.1,1.1c-0.2,0.2-0.4,1.4,0,2.1c0.3-0.6,1-1.1,1.5-1.2c0.6-0.1,1.2,0.2,1.7,0.4c1,0.5,2,1.4,2.8,2.1
				c0.9,0.8,2.4,2.6,2.4,2.6C246.5,130.9,245.2,128.4,244.9,127.7z"
            />
            <g>
              <path className="st9" d="M240.6,137.4h0.1l0,0H240.6z" />
              <path
                className="st3"
                d="M240.9,151.1c-0.2-0.6-1.9-7.6-0.1-12.6c0.1-0.4,0.5-1.1,0.5-1.1c-3.4,0.5-3,4.1-3,5.6
					c0.1,2.2,0.6,5.5,1.5,7.6c0.3,0.7,1.2,2.2,2,2.1C241.4,152.4,241.1,151.6,240.9,151.1z"
              />
            </g>
            <path
              id="right_x5F_arm_4_"
              className="st2"
              d="M248.9,159.3c-0.1,0.7-0.3,1.5-0.6,2.2c0,0-0.2,0.6-0.6,1.5c-0.1,0.1-0.1,0.3-0.1,0.3
				c-0.1,0.4-14,34.6-27.3,35.5c-10.5,0.7-24.5-19-29.5-49.2c-1.3-7.7-2.6-15.3-3.9-23c0,0,17.1,2.4,22.2,15.5
				c7.1,18.2,12.2,34.6,12.2,34.6c7.4-7.6,10.5-11.9,11.9-14.5c0.6-1.2,1.3-2.4,2-3.6c1.2-1.4,2.1-2.7,2.6-3.6
				c0.5-1,1.1-2.1,1.3-2.6c1.6-1.3,4.5-2.2,6.7-1.2C248.1,152.3,249.6,155.6,248.9,159.3z"
            />
            <path
              id="shadow_x5F_right_x5F_arm_2_"
              className="st3"
              d="M221.3,176.7l-3.3,2.8c0,0-1.1-16.2-2.6-20.4L221.3,176.7z"
            />
          </g>
          <path
            id="skirt_2_"
            className="st10"
            d="M211.4,267.9H96.7c0.7-2.3,1.5-4.5,2.3-6.4c6.2-15.6,14.1-23.9,23.3-47.3
			c2.6-6.4,4.5-12.3,6.1-17.2c1.2-3.8,2.1-7.1,2.8-9.8c19.9,1.1,39.9,2.2,59.8,3.3c0.6,2.1,1.2,4.3,1.9,6.5
			c3.3,11.6,6.5,23.4,9.6,35.5C205.6,244.4,208.6,256.3,211.4,267.9z"
          />
          <path
            id="back_x5F_left_x5F_arm_2_"
            className="st3"
            d="M111.4,188.1c3.9-2.5,6.7-13.2,10.4-37.9c1.2-8,1.7-11.5,1-16.5
			c-0.3-2.3-1.6-11.8-7-13.6c-3.6-1.2-7.3,0.8-8,1.2c-8,5.2-8.6,16.5-8.5,38.6c0.1,17.5,0.5,28.3,3.4,29.6
			C106.3,191.3,110.8,188.5,111.4,188.1z"
          />
          <g id="chest_1_">
            <g id="chest_4_">
              <g>
                <path
                  className="st11"
                  d="M99.9,140.4c0-1.8,0.2-12.4,8.6-20.2c7.2-6.6,17.7-8.7,27.4-5.6c3.9,7.3,7.6,9.3,10.4,9.7
						c3.3,0.4,5.5-1.4,8.2,0.1c3.4,1.9,3.1,6.6,5.1,6.8c1.4,0.1,1.9-2.2,4.1-4.7c4.1-4.4,8.4-2.9,13.1-6.4c1.9-1.4,4.3-4,5.8-8.9
						c3.1-0.2,7.4-0.1,12.3,1.5c0,0,0.5,0.1,0.9,0.3c14.4,5.4,18.3,23.5,18.3,23.5c-4.4,5.7-10.2,10-15.1,12.9
						c2.7,20.4-8,41.1-8,41.1c-8,1.5-18,2.7-29.6,2.5c-12.2-0.2-22.6-1.9-30.8-3.9c-2.9-11.9-5.8-23.8-8.7-35.7L99.9,140.4z"
                />
              </g>
            </g>
            <g id="shadow_x5F_chest_2_" className="st12">
              <g>
                <path
                  className="st13"
                  d="M141.3,169.8c9.2,4,19.3,6.2,29.2,7.7c8.2,1.2,16.8,2.5,25.1,1.7c-2.2,6.8-4.5,11.3-4.5,11.3
						c-10.4,1.9-23.9,3.3-39.5,2c-7.8-0.7-14.8-1.9-20.9-3.3c-2.7-11.2-5.4-22.4-8.1-33.7C126.8,162.2,134,166.6,141.3,169.8z"
                />
              </g>
            </g>
          </g>
          <g id="right_x5F_arm_1_">
            <path
              className="st2"
              d="M111.6,147.8c1.3,8.4,2.2,8.5,3.4,18c0.8,6.5,0.2,10.7-1.2,15.1c-0.9,2.9-1.2,6-4.9,8
				c-0.6,0.3-3.4,2.3-7.5,0.7c-0.6-0.2-5.8-4.1-7.5-15.3c-0.9-6.1-0.6-21.9,2.7-35.2c4-16.7,3.4-14,4.9-19
				c1.5-4.9,1.9-13.4,7.3-14.6c1.8-0.4,3.7,1,4.6,2.4c1.2,1.9-0.2,4-0.7,7.3C110.3,128.8,110.3,139.2,111.6,147.8z"
            />
            <path
              className="st3"
              d="M114.5,93.9c0.7-2.5,2.1-4.4,2.7-6.9c0-0.2,0.1-0.8,0.6-1.2c1.2-0.9,3.9-1.2,6.7-2.7
				c0.3-0.2,2.2-1.4,2.7-1.1c0.4,0.3,0.8,0.9,0.7,1.4c-0.1,0.3,0,1.5-1.5,2.5c-2.5,1.6-5.4,2.3-5.7,2.6c-0.6,0.5-0.1,2.4-0.1,3.2
				c-0.1,6-3.3,7.1-3,9.7c0.1,0.5,0.3,1.4,0.9,1.6c0.9,0.4,1.6,0.1,4.3-1c0.5-0.2,6.1-2.5,7.3-0.9c0.4,0.6-0.1,1.6-0.7,2
				c-0.5,0.3-2.3,1.3-4.1,2c-2.6,1.1-3.1,1.3-4.1,2c-1,0.8-0.8,0.4-1.9,1.7c-1.6,1.9-3.7,2.8-4.8,3.2c-1.9,0.7-4.5,0.6-5.8-0.5
				c-1.2-1.1-0.5-2.4,0.4-5.6C111.2,99.3,113.1,99.3,114.5,93.9z"
            />
            <path
              className="st2"
              d="M126.2,96.5c0.3,0,0.8-0.1,1.4,0c0.6,0.1,1,0.4,1.9,0.8c2.1,1.2,1.9,0.9,2.3,1.2c1,0.8,0.8,1.2,1.9,2.1
				c0.5,0.4,1.9,1.1,3.3,0.2c0.5-0.3,0.2-1.1,0.2-1.2c-0.1-0.4-0.3-0.6-1.2-1.7c-0.7-0.8-0.9-1.1-0.8-1.2c0.1-0.1,1.2,0.2,1.2-0.8
				c0-0.5-0.3-0.9-0.8-1.4c-1.7-1.9-1.9-2.5-3.1-3.3c-0.2-0.1-0.7-0.5-1.9-0.8c-3.1-0.8-4.8-2.1-6.2-1.7c-1.4,0.4-10.4,3.2-11,3.5
				c-1.1,0.6-1.5,1.7-1.7,2.1c-1.4,3.5-2.6,4.4-5.8,11.6c-1.3,2.8-1.8,3.9-1.3,5c0.7,1.3,2.4,1.6,3.3,1.8c0.4,0.1,2.9,0.6,5-0.8
				c1-0.7,1.5-1.6,2.5-3.3c1.7-3.1,1.7-4.7,3.1-5.4c0.1-0.1,1-0.5,2.3-0.8c0.4-0.1,0.7-0.2,0.8-0.2c1.7-0.2,4.1,0.5,4.4,0.6
				c0.4,0.1,1.5,1.3,4.8,3.1c0.4,0.2,2.3,1.2,3.1,0.4c0.4-0.4,0.2-1,0.2-1c-0.2-0.7-1-1.1-1.7-1.4c-2-1.1-1.6-2-3.9-3.5
				c-0.7-0.5-1.4-0.9-2.3-1.2c-3.1-1.2-5.2-0.2-5.4-1C120.6,97.4,122.9,97,126.2,96.5z"
            />
          </g>
        </g>
        <g id="white-shirt">
          <path
            id="shirt"
            className="st7"
            d="M191,190.5c-5.2,1-11.1,1.8-17.6,2.2c-0.7-9.1-1.3-18.1-2-27.2v-18.8l1.1-19.1l-4-8
			c-1.2,0.6-2.6,1.4-4.1,2.7c-0.7,0.6-1.3,1.2-1.8,1.8c-0.4,0.4-1,0.9-1.5,1.6c-0.8,1.1-1.3,2.2-1.5,3c-0.3-0.8-0.9-2-2-3.1
			c-0.6-0.6-1.2-1.1-1.7-1.4c-1.5-1.8-2.9-3.5-4.4-5.3l-3.3,7.5l4.9,22.1l0.8,18.6c-1.3,8.4-2.5,16.8-3.8,25.2c0.1,0,0.2,0,0.2,0
			l0,0c-7.2-0.7-13.8-1.9-19.6-3.2c-1.7,5.5-3.3,11-5,16.5c8.3,2.1,19.3,4.1,32.1,4.3c15.4,0.2,28.2-2.2,37.5-4.7
			C193.8,200.3,192.4,195.4,191,190.5z"
          />
          <path
            id="left_x5F_shadow"
            className="st14"
            d="M135.7,107.1l4.3,6.8l3.7-0.4l-0.2-0.6c0,0,0.7-1.3,1.4-2.9c0.6-1.1,0.7-4.2,0.8-5.9
			L135.7,107.1z"
          />
          <path
            id="shadow_x5F_right"
            className="st14"
            d="M179,104.9l-12.9-2.1c0,0,1.2,5.4,3.9,7.3c2,1.4,2.9,1.4,2.9,1.4L179,104.9z"
          />
          <path
            id="right_x5F_collar"
            className="st7"
            d="M184.8,109.7c-1.9-1.6-3.9-3.2-5.8-4.8c-4.4,4.8-8.8,9.5-13.1,14.2l-1.9,9.5l4.5-4.5
			l4.2,7.9C176.7,124.6,180.8,117.1,184.8,109.7z"
          />
          <path
            id="left_x5F_collar"
            className="st7"
            d="M135.6,107l-6.1,4.2l17.8,20.8c1.2-3,2.5-6,3.8-8.9c2.2,2,4.5,3.9,6.7,5.9
			c-2.1-3.4-4.2-6.8-6.3-10.1C146.2,114.9,140.9,111,135.6,107z"
          />
          <path
            id="front_x5F_left_x5F_collar"
            className="st14"
            d="M147.4,132c1.4-4.4,2.8-8.8,4.1-13.1c2,2.2,4.1,4.5,6.1,6.7
			c-1.6-0.8-3.2-1.6-4.8-2.4c-0.6,0.8-1.2,1.7-1.9,2.6C149.5,128,148.3,130.1,147.4,132z"
          />
          <path
            id="front_x5F_right_x5F_collar"
            className="st14"
            d="M172.7,132c-2.3-4.3-4.6-8.5-6.9-12.8c-1.6,2.1-3.1,4.3-4.7,6.4
			c1.5-0.6,2.9-1.3,4.4-2c1,0.7,2.1,1.6,3.1,2.8C170.5,128.5,171.8,130.4,172.7,132z"
          />
        </g>
        <g id="headphone_2_">
          <path
            className="st7"
            d="M158.6,21.9c5.4-0.1,8.1,1.2,9.2,1.9l0,0l-0.1-0.1c-0.5-0.5-3.6-3.1-10.5-3.1c-16.4-0.2-28.7,13-30.1,30.8
			c-0.9,0.1-1.8,0.5-2.3,1.2c-2.5,3.3-2.9,8.6-2.9,13.2c0,4.2,1.8,12.4,3.4,13.6c1.9,1.4,4.7,0.8,5.1,0.6c0.5-0.2,0.9-0.5,1.3-0.9
			c3.3,7.9,9.8,13.1,17.1,13.1c1.2,0,2.3-0.1,3.3-0.4c0.7,0.6,1.8,1.1,3.2,1.1c2.1,0,3.8-1,3.8-2.3s-1.7-2.3-3.8-2.3s-3.8,1-3.8,2.3
			v0.1c-0.6,0.1-1.2,0.1-1.8,0.1c-7.1,0-13.3-6.8-15.8-15.8c0.8-2.4,1.2-5.5,1.2-9.2c0-7.3-1.7-12.9-4.2-14
			C132.5,35.9,144.9,22.1,158.6,21.9z"
          />
          <ellipse className="st15" cx="127.2" cy="65.8" rx="4.1" ry="12.4" />
        </g>
      </g>
    </svg>
  </div>


</div>

      <div className="footer-bottom fl-wrap footerbottom" style={{ marginTop: "100px" }}>
        <div className="copyright">© FoodFusion 2024 . All rights reserved. </div>
        <div className="designedby">
          <a href='https://jatinsidana.netlify.app/'> <h5 className='copyright'>Designed by Jatin Sidana</h5></a>
        </div>
      </div>



    </>
  )
}
export default AdminShowPlan;
