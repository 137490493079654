import { useEffect, useState } from "react";
import AdminNavbar from "./AdminNavbar";
import Swal from "sweetalert2";
import { event } from "jquery";

export const UserViewDetails = () => {
    const[userDetails,setUserDetails] = useState([]);
const [planNumber, setPlanNumber] = useState("");
    const [planCost, setPlanCost] = useState("");
    const [planlimit, setPlanLimit] = useState("");
    const [orderlimit, setOrderLimit] = useState("");
    const [planstatus, setPlanStatus] = useState("");
    const [selectedEmail, setSelectedEmail] = useState("");

    const getUserDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}getuserdetails`, { method: "GET" });
            const text = await response.text();
            const data = JSON.parse(text.trim());
            setUserDetails(data.ans);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    useEffect(()=>{
    getUserDetails();
    },[])
const addCustomPlan = async (e) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append('plannumber', planNumber);
        formdata.append('plancost', planCost);
        formdata.append('planlimit', planlimit);
        formdata.append('orderlimit', orderlimit);
        formdata.append('planstatus', planstatus);
        formdata.append('useremail', selectedEmail);
    
        const url = `${process.env.REACT_APP_API_URL}addcustomplan`;
        try {
          const response = await fetch(url, { method: "POST", body: formdata });
          const ans = await response.text();
          if (ans === "success") {
            setPlanNumber('');
            setPlanCost('');
            setPlanLimit('');
            setOrderLimit('');
            setPlanStatus('');
            Swal.fire({
              icon: 'success',
              title: 'Plan Added Successful',
              text: 'You have successfully Added Plan!',
              showConfirmButton: true,
            })
             
          } else if (ans === "fail") {
            Swal.fire({
              icon: 'error',
              title: 'Plan already exist',
              text: 'Add New Plan!',
              showConfirmButton: true,
            })
          } else if (ans === "exception") {
            alert(ans);
          }
        } catch (error) {
          console.error('Error adding item:', error);
        }
    }
    const handelEmail =(email) =>{
         setSelectedEmail(email);
    }

    return (
        <>
        <AdminNavbar/>
        <br/>
        <br/>
        <br/>
        <br/>
            <div className="container mt-3">
                <h2>User details</h2><br/>
             <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover table-dark">
                    <thead className="table-success">
                        <tr>
                            <th>User Email</th>
                            <th>User Name</th>
                            <th>User Number</th>
                            <th>User Subscriptions</th>
                            <th>Current Plan</th>
                        </tr>
                    </thead>
                    <tbody>
                    {userDetails.map((details,index) =>(

                  
                        <tr key={index}>
                            
                            <td> {details.user_email} </td>
                            <td>{details.user_name}</td>
                            <td>{details.user_number}</td>
                            <td>{details.subscription}</td>
 <td><button type="button" className="btn btn-outline-success" data-bs-toggle="modal" 
                                     data-bs-target="#myModal"
                                      onClick={() =>handelEmail(details.user_email)}>Add Plan</button></td>                            
                        </tr>
                       
                    ))}      
                    </tbody>
                </table>
                </div>
            </div>;

  <div className="modal fade" id="myModal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title">Add Plan  for {selectedEmail}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" />
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <form
                                className="custom-form"
                               
                                id="reservationform"
                            >
                                <fieldset>
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Plan Number *"
                                                defaultValue=""
                                                value={planNumber}
                                                onChange={(e) => setPlanNumber(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Plan Cost *"
                                                defaultValue=""
                                                value={planCost}
                                                onChange={(e) => setPlanCost(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Menu Limit *"
                                                defaultValue=""
                                                value={planlimit}
                                                onChange={(e) => setPlanLimit(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Order Limit *"
                                                defaultValue=""
                                                value={orderlimit}
                                                onChange={(e) => setOrderLimit(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Plan Active/InActive *"
                                                defaultValue=""
                                                value={planstatus}
                                                onChange={(e) => setPlanStatus(e.target.value)}
                                            />
                                        </div>



                                    </div>


                                    <button className="btn color-bg"  style={{ backgroundColor: "#C19D60" }} onClick={(e)=> addCustomPlan(e)} >
                                        Add Now <i className="fal fa-long-arrow-right" />
                                    </button>
                                    
                                </fieldset>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
