import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

const AdminEditPlan = () => {
    const [adminPlan, setAdminPlan] = useState([]);
    const [planId, setPlanId] = useState("");
    const [selectedPlanPrice, setSelectedPlanPrice] = useState("");
    const [selectedPlanNummber, setSelectedPlanNumber] = useState("");
    const [selectedPlanOrder, setSelectedPlanOrder] = useState("");
    const [selectedPlanMenu, setSelectedPlanMenu] = useState("");
    const [selectedPlanStatus, setSelectedPlanStatus] = useState("");
    const [editPlanNumber, setEditPlanNumber] = useState("");
    const [editPlanCost, setEditPlanCost] = useState("");
    const [editPlanMenu, setEditPlanMenu] = useState("");
    const [editPlanOrder, setEditPlanOrder] = useState("");
    const [editPlanStatus, setEditPlanStatus] = useState("");


 const username = localStorage.getItem("un");
    const navigate = useNavigate();

    useEffect(() => {
        if (!username) {
            navigate('/adminlogin');
        }
    }, [username, navigate]);

    const showPlan = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}showplans`, { method: "POST" });
            const text = await response.text();
            const data = JSON.parse(text.trim());
            setAdminPlan(data.ans);
            console.log(adminPlan)

        } catch (error) {
            console.error('Error fetching menu items:', error);
        }

    }

    useEffect(() => {
        showPlan();

    }, [])
    const handleEditClick = (planId) => {
        setPlanId(planId); // Set the plan ID
        const selectedPlan = adminPlan.find(plan => plan.id === planId); // Find the plan by ID
        if (selectedPlan) {
            setSelectedPlanPrice(selectedPlan.plan_cost); // Set the plan price
            setSelectedPlanNumber(selectedPlan.plan_number);
            setSelectedPlanOrder(selectedPlan.order_limit);
            setSelectedPlanStatus(selectedPlan.plan_status);
            setSelectedPlanMenu(selectedPlan.menu_limit);

        }
    };
    const EditPlan = async (event) => {
        event.preventDefault();
        const formdata = new FormData();
        formdata.append('planid', planId);
        formdata.append('plannumber', editPlanNumber ? editPlanNumber:selectedPlanNummber);
        formdata.append('plancost', editPlanCost ? editPlanCost:selectedPlanPrice);
        formdata.append('planmenu', editPlanMenu ? editPlanMenu : selectedPlanMenu);
        formdata.append('planorder', editPlanOrder ? editPlanOrder : selectedPlanOrder);
        formdata.append('planstatus', editPlanStatus ? editPlanStatus : selectedPlanStatus);

        const url = `${process.env.REACT_APP_API_URL}editplan`;
        try {
            const response = await fetch(url, { method: "POST", body: formdata });
            const ans = await response.text();
              if (ans === "success") {
              
                Swal.fire({
                  icon: 'success',
                  title: 'Plan Edit Successful',
                  text: 'You have successfully Edited Plan!',
                  showConfirmButton: true,
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/admineditplan";
                    }
                  });

          showPlan();

              } else if (ans === "fail") {
                Swal.fire({
                  icon: 'error',
                  title: 'Plan cannot edited',
                  text: 'edit New Plan!',
                  showConfirmButton: true,
                })
              } else if (ans === "exception") {
                alert(ans);
              }
        } catch (error) {
            console.error('Error adding item:', error);
        }
    }

    return (
        <>

            <section className="parallax-section hero-section hidden-section" data-scrollax-parent="true">
                <img className="bg par-elem" src="images/bg/17.jpg" alt="img" data-scrollax="properties: { translateY: '30%' }" />
                <div className="overlay" />
                <div className="container">
                    <div className="section-title" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <h4>Edit Plans</h4>
                        <h2>Admin Edit Plan</h2>
                        <div className="dots-separator fl-wrap">
                            <span />
                        </div>
                    </div>
                </div>
                <div className="hero-section-scroll">
                    <div className="mousey">
                        <div className="scroller" />
                    </div>
                </div>
                <div className="brush-dec" />
            </section>


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />



            <div className="plancard-container">
                {adminPlan.map((plan, index) => (
                    <div className="plancard" key={index}>
                        <div className="active-badge">{plan.plan_status}</div>
                        <h2 className="plancard-title">Premium Plan {plan.plan_number}</h2>
                        <p className="planprice">
                            ${plan.plan_cost}<span>/month</span>
                        </p>
                        <ul className="planfeatures">
                            <li>manage menu,users and orders</li>
                            <li>24/7 Customer Support</li>
                            <li>{plan.menu_limit} Food Items </li>

                            <li>Limited to {plan.order_limit} Orders/Day</li>

                        </ul>
                        <button className="plansubscribe-btn" data-bs-toggle="modal"
                            data-bs-target="#myModalPlanEdit" onClick={() => handleEditClick(plan.id)} >edit</button>

                    </div>
                ))}

            </div>
            <div className="modal fade" id="myModalPlanEdit">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Plans</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" />
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">
                            <p>Plan ID: <span id="modal-plan-id"></span></p>

                            <form
                                className="custom-form"
                                action="https://restabook.kwst.net/light/php/reservation.php"
                                name="reservationform"
                                id="reservationform"
                            >
                                <fieldset>
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <span>Plan Number :</span>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder={selectedPlanNummber}
                                                defaultValue=""
                                                value={editPlanNumber}
                                              
                                                onChange={(e) => setEditPlanNumber(e.target.value)}


                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <span>Plan Cost :</span>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder={selectedPlanPrice}
                                                defaultValue=""
                                                value={editPlanCost}
                                                onChange={(e) => setEditPlanCost(e.target.value)}

                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <span>Plan Menu Limit :</span>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder={selectedPlanMenu}
                                                defaultValue=""
                                                value={editPlanMenu}
                                                onChange={(e) => setEditPlanMenu(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <span>Plan Orders Limit :</span>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder={selectedPlanOrder}
                                                defaultValue=""
                                                value={editPlanOrder}
                                                onChange={(e) => setEditPlanOrder(e.target.value)}

                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <span>Plan Status :</span>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder={selectedPlanStatus}
                                                defaultValue=""
                                                value={editPlanStatus}
                                                onChange={(e) => setEditPlanStatus(e.target.value)}

                                            />
                                        </div>



                                    </div>


                                    <button className="btn color-bg" id="reservation-submit" style={{ backgroundColor: "#C19D60" }} onClick={EditPlan} >
                                        Edit Now <i className="fal fa-long-arrow-right" />
                                    </button>
                                </fieldset>
                            </form>

                        </div>

                        {/* Modal footer */}

                    </div>
                </div>
            </div>


            <div className="footer-bottom fl-wrap footerbottom" style={{ marginTop: "100px" }}>
                <div className="copyright">© FoodFusion 2024 . All rights reserved. </div>
                <div className="designedby">
                    <a href='https://jatinsidana.netlify.app/'> <h5 className='copyright'>Designed by Jatin Sidana</h5></a>
                </div>
            </div>

        </>
    )
}
export default AdminEditPlan;
